<template>
  <div
    class="main-page-brand-sale dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top"
  >
    <Intersection @onIntersection="onIntersectionPromotions()">
      <LoadingComponent v-if="state.loading" />
      <template v-else>
        <div class="dosiin-info-brand-sale">
          <div class="banner-brand-sale">
            <div class="item-banner-brand-sale">
              <img
                v-lazy="{
                  src: _bannerImage(product.company_data.banners_main),
                  loading: _bannerDefault,
                  error: _bannerDefault,
                }"
                height="375"
                class="dosiin_w-100 banner-sale"
              />
            </div>
          </div>
          <div class="info-detail-brand-sale">
            <div class="avatar-brand">
              <BrandLogo :brand="product.company_data" :width="96" :height="96" />
            </div>
            <a
              :href="_brandUrl(product.company_data.seo_name)"
              :title="product.company_data.company"
            >
              <div class="name-brand" v-text="product.company_data.company"></div>
            </a>
            <div class="brand-info-detail-item">
              <div class="brand-follower">
                <b v-text="product.company_data.follow_count"></b>người theo dõi
              </div>
              <div class="quantity-like">
                <b v-text="product.company_data.like_live"></b>lượt thích
              </div>
            </div>
          </div>
        </div>

        <div class="page-section promotion-detail">
          <div class="dosiin-event-page best-promotion">
            <div class="info-promotion sticky-content">
              <div class="section-title-wrapper">
                <h2 class="section-title">Thương hiệu đang khuyến mãi</h2>
              </div>
              <div class="detail-coupon dosiin-hidden">
                <ul class="promo-list">
                  <li class="promo-item">
                    <svg
                      viewBox="0 0 339 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M256 12C259.826 12 263.234 10.2094 265.431 7.42042C268.166 3.9499 271.582 0 276 0H331C335.418 0 339 3.58172 339 8V62C339 66.4183 335.418 70 331 70H276C271.582 70 268.166 66.0501 265.431 62.5796C263.234 59.7906 259.826 58 256 58C252.174 58 248.766 59.7906 246.569 62.5796C243.834 66.0501 240.418 70 236 70H8C3.58173 70 0 66.4183 0 62V8C0 3.58172 3.58172 0 8 0H236C240.418 0 243.834 3.94989 246.569 7.42042C248.766 10.2094 252.174 12 256 12ZM256 15C255.724 15 255.5 15.2239 255.5 15.5V18.3333C255.5 18.6095 255.724 18.8333 256 18.8333C256.276 18.8333 256.5 18.6095 256.5 18.3333V15.5C256.5 15.2239 256.276 15 256 15ZM256.5 21.6667C256.5 21.3905 256.276 21.1667 256 21.1667C255.724 21.1667 255.5 21.3905 255.5 21.6667V28.3333C255.5 28.6095 255.724 28.8333 256 28.8333C256.276 28.8333 256.5 28.6095 256.5 28.3333V21.6667ZM256.5 31.6667C256.5 31.3905 256.276 31.1667 256 31.1667C255.724 31.1667 255.5 31.3905 255.5 31.6667V38.3333C255.5 38.6095 255.724 38.8333 256 38.8333C256.276 38.8333 256.5 38.6095 256.5 38.3333V31.6667ZM256.5 41.6667C256.5 41.3905 256.276 41.1667 256 41.1667C255.724 41.1667 255.5 41.3905 255.5 41.6667V48.3333C255.5 48.6095 255.724 48.8333 256 48.8333C256.276 48.8333 256.5 48.6095 256.5 48.3333V41.6667ZM256.5 51.6667C256.5 51.3905 256.276 51.1667 256 51.1667C255.724 51.1667 255.5 51.3905 255.5 51.6667V54.5C255.5 54.7761 255.724 55 256 55C256.276 55 256.5 54.7761 256.5 54.5V51.6667Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M329 0C333.418 0 337 3.58172 337 8V62C337 66.4183 333.418 70 329 70H331C335.418 70 339 66.4183 339 62V8C339 3.58172 335.418 0 331 0H329Z"
                        fill="url(#paint0_linear_10873_28737)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_10873_28737"
                          x1="329"
                          y1="0"
                          x2="348.6"
                          y2="2.8"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#ED55C7" />
                          <stop offset="1" stop-color="#6756CA" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div class="info-coupon">
                      <div class="promo-content">
                        <div class="group-promo-name d-flex">
                          <span class="dosiin_mr-2 promo-name promo-name-coupon-discount"
                            >Mã giảm giá</span
                          >
                          <span class="promo-name promo-name-brand">Maverick</span>
                        </div>
                        <span class="promo-detail">Ưu đãi 30k đơn từ 200k</span>
                        <div class="group-info d-flex">
                          <span class="promo-info-icon dosiin_mr-2"></span>
                          <span class="promo-exp"
                            >HSD:
                            <span class="text-pink-gradient">còn 27 ngày</span></span
                          >
                        </div>
                      </div>
                      <div class="copy-promo">
                        <button
                          class="dossin_copy-promo-btn btn copy-promo-btn text-pink-gradient"
                          type="button"
                          name="Rookie"
                          value="promo1"
                        >
                          <span class="text-pink-gradient">Nhận</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="desc dosiin-detail-coupon">
                <h4 class="title-coupon dosiin-hidden">Ưu đãi khuyến mãi</h4>
                <ul class="description-coupon dosiin-hidden">
                  <li>Đơn hàng giảm giá: 20,000₫</li>
                </ul>
                <div class="desc dosiin-hidden"></div>
                <h4 class="title-coupon dosiin-hidden">Điều kiện áp dụng</h4>
                <ul class="description-coupon dosiin-hidden">
                  <li>Không áp dụng chung với khuyến mãi khác</li>
                  <li>Giảm tối đa 20,000 ₫</li>
                  <li>Hạng thành viên: Thành viên Dosiin</li>
                  <li>Mã giảm giá: JUNE20K</li>
                </ul>
                <div class="desc dosiin-hidden"></div>
                <h4 class="title-coupon">Hạn sử dụng</h4>
                <ul class="description-coupon">
                  <li>
                    Từ <span>{{ _momentDateMonthYear(product.time_start) }}</span> đến
                    <span>{{ _momentDateMonthYear(product.time_end) }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="line-promotion"></div>
            <div class="event-right-page">
              <div class="section-title-wrapper">
                <div class="title-result">
                  <h2 class="section-title">Thương hiệu đang khuyến mãi</h2>
                  <div class="result-product-sale dosiin-hidden">(128 kết quả)</div>
                </div>
                <div class="view-all" style="display: none">
                  <span class="view">Hiển thị:</span>
                  <a class="categories-list active" href="">
                    <i class="dsi dsi-act-fill-grid"></i>
                  </a>
                  <div class="line-promotion"></div>
                  <a class="categories-list" href="">
                    <i class="dsi dsi-fill-radiolist"></i>
                  </a>
                </div>
              </div>

              <div class="grid grid-25">
                <template v-if="product.products.length != 0">
                  <div
                    class="grid-item item-sale"
                    v-for="(product, i) in product.products"
                    :key="i"
                  >
                    <ProductItem :product="product" />
                  </div>
                </template>
                <p v-else>Không tìm thấy sản phẩm</p>
              </div>

              <template v-if="product.products.length != 0">
                <Pagination
                  v-if="state.totalPage > 1 && !state.hidePagination"
                  v-model="state.page"
                  :pages="state.totalPage"
                />
              </template>
            </div>
          </div>
        </div>

        <BrandsSuggestHorizon
          wrapperClass="page-section brand-promotion"
          title="Thương hiệu đang khuyến mãi"
          gridClass="grid-20"
          :brands="state.promotions_brand"
          :options="{
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 9,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          }"
        />
      </template>
    </Intersection>
  </div>
</template>

<script>
import ProductItem from "@/components/product/ProductItem";
import { inject, onMounted, ref, watch } from "@vue/runtime-core";
import Pagination from "@/components/pagination/Pagination";
import BrandsSuggestHorizon from "@/components/sliders/BrandsSuggestHorizon";

export default {
  components: {
    ProductItem,
    Pagination,
    BrandsSuggestHorizon,
  },
  setup() {
    const state = inject("state");
    const product = inject("product");
    const currentUser = inject("currentUser");
    const onIntersectionPromotions = inject("onIntersectionPromotions");

    return {
      product,
      state,
      modules: [Pagination],
      onIntersectionPromotions,
    };
  },
};
</script>
<style>
.brand_on-promo .brand-list .brand-logo_img {
  width: 62px !important;
  height: 62px !important;
}
</style>
