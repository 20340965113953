<template>
  <header>
    <div class="sticky-header brand-detail-sticky-header">
      <div class="go-back-icon dosiin_go-back cm-back-link" @click="goBack">
        <i class="dsi dsi-chevron-left"></i>
        <a class="text">Quay lại</a>
      </div>
      <div class="header-center center" v-if="extend_view">
        <h2 class="text-grey-lg brand-shop-header_title center">{{ brand.company }}</h2>
        <h5 class="text-grey-sm brand-shop-header_subtitle">
          {{ brand.products_count }} Sản phẩm
        </h5>
      </div>
      <div class="header-right">
        <button
          class="social-interact-btn dosiin_social-interact-btn btn-circle brand-nav-btn"
          data_item_followed="followed"
          data_item_liked="not_like"
          v-if="!extend_view"
        >
          <svg
            width="16"
            height="6"
            viewBox="0 0 16 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="three-dots-custom"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.7415 3.00015C4.7415 1.74259 3.72205 0.723145 2.4645 0.723145C1.20695 0.723145 0.1875 1.74259 0.1875 3.00015C0.1875 4.2577 1.20695 5.27715 2.4645 5.27715C3.72205 5.27715 4.7415 4.2577 4.7415 3.00015ZM10.277 3.00015C10.277 1.74259 9.25755 0.723145 8 0.723145C6.74245 0.723145 5.723 1.74259 5.723 3.00015C5.723 4.2577 6.74245 5.27715 8 5.27715C9.25755 5.27715 10.277 4.2577 10.277 3.00015ZM15.8125 3.00015C15.8125 1.74259 14.7931 0.723145 13.5355 0.723145C12.2779 0.723145 11.2585 1.74259 11.2585 3.00015C11.2585 4.2577 12.2779 5.27715 13.5355 5.27715C14.7931 5.27715 15.8125 4.2577 15.8125 3.00015ZM1.3023 3.00015C1.3023 2.35828 1.82263 1.83794 2.4645 1.83794C3.10637 1.83794 3.6267 2.35828 3.6267 3.00015C3.6267 3.64201 3.10637 4.16235 2.4645 4.16235C1.82263 4.16235 1.3023 3.64201 1.3023 3.00015ZM6.8378 3.00015C6.8378 2.35828 7.35813 1.83794 8 1.83794C8.64187 1.83794 9.1622 2.35828 9.1622 3.00015C9.1622 3.64201 8.64187 4.16235 8 4.16235C7.35813 4.16235 6.8378 3.64201 6.8378 3.00015ZM13.5355 4.16235C12.8936 4.16235 12.3733 3.64201 12.3733 3.00015C12.3733 2.35828 12.8936 1.83794 13.5355 1.83794C14.1774 1.83794 14.6977 2.35828 14.6977 3.00015C14.6977 3.64201 14.1774 4.16235 13.5355 4.16235ZM8 1.73378C8.6994 1.73378 9.26637 2.30075 9.26637 3.00015C9.26637 2.30075 8.6994 1.73377 8 1.73378Z"
              fill="#808080"
            ></path>
          </svg>
        </button>
        <a
          class="dosiin_cart-header-btn brand_cart-btn btn-circle brand-nav-btn cart-icon"
          href="https://dosi-in.com/cart/"
        >
          <svg
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7809 6.80932H13.3328V4.80893C13.3328 4.24298 13.2211 3.68259 13.0041 3.15976C12.787 2.63693 12.469 2.16192 12.068 1.76182C11.6671 1.36173 11.1912 1.0444 10.6674 0.827909C10.1436 0.611418 9.58231 0.5 9.01543 0.5C8.44856 0.5 7.88722 0.611418 7.36347 0.827909C6.83971 1.0444 6.36377 1.36173 5.96283 1.76182C5.5619 2.16192 5.24382 2.63693 5.0268 3.15976C4.80978 3.68259 4.69808 4.24298 4.69808 4.80893V9.24765C4.69808 9.38161 4.75141 9.50996 4.84614 9.60448C4.94084 9.69899 5.06915 9.75196 5.2028 9.75196C5.33644 9.75196 5.46475 9.69899 5.55945 9.60448C5.65418 9.50996 5.70752 9.38161 5.70752 9.24765V7.81795H10.791V6.80932H5.70752V4.80893C5.70752 3.93385 6.05587 3.09447 6.67615 2.4755C7.29645 1.8565 8.13791 1.50863 9.01543 1.50863C9.89296 1.50863 10.7344 1.8565 11.3547 2.4755C11.975 3.09447 12.3234 3.93385 12.3234 4.80893V9.21594C12.3234 9.34991 12.3767 9.47825 12.4714 9.57278C12.5661 9.66728 12.6944 9.72026 12.8281 9.72026C12.9617 9.72026 13.09 9.66728 13.1847 9.57278C13.2795 9.47825 13.3328 9.34991 13.3328 9.21594V7.81795H16.7714V19.4914H1.25944V7.81795H3.16576V6.80932H0.25V19.4187C0.25 19.7057 0.364258 19.9808 0.567427 20.1835C0.770574 20.3862 1.04596 20.5 1.33297 20.5H16.6979C16.9849 20.5 17.2603 20.3862 17.4634 20.1835C17.6666 19.9808 17.7809 19.7057 17.7809 19.4187V6.80932Z"
              fill="#484848"
            ></path>
          </svg>
          <span class="item-badge" id="dosiin_amount_cart" style="top: 0.5px">
            {{ cartAmount }}
          </span>
        </a>
      </div>
    </div>
    <div class="brand-detail-header" id="dosiin_page-header">
      <button
        class="brand_nav-back-btn btn-circle brand-nav-btn"
        type="button"
        @click="goBack"
      >
        <svg
          class="icon icon-arrow-back"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.87139 1.70549L2.57214 7.95021L8.9575 14.2803C9.0967 14.4183 9.16683 14.5915 9.16683 14.7859C9.16683 14.9803 9.0967 15.1536 8.9575 15.2916C8.8906 15.3579 8.81353 15.4109 8.72624 15.447C8.63887 15.483 8.54643 15.5 8.45145 15.5C8.36173 15.5 8.27399 15.4814 8.18971 15.4493C8.09717 15.414 8.01529 15.3608 7.94541 15.2916L1.04283 8.44873C0.905227 8.31232 0.833496 8.14215 0.833496 7.95021C0.833496 7.75827 0.905227 7.5881 1.04283 7.45168L7.84496 0.70844C7.98428 0.570328 8.16007 0.5 8.35818 0.5C8.55628 0.5 8.73208 0.570328 8.87139 0.70844C9.009 0.844852 9.08073 1.01502 9.08073 1.20697C9.08073 1.39891 9.009 1.56908 8.87139 1.70549Z"
            fill="#F3F3F3"
          ></path>
        </svg>
      </button>
      <div class="back-header-right-side">
        <button
          class="social-interact-btn dosiin_social-interact-btn btn-circle brand-nav-btn"
          v-if="!extend_view"
          @click="menuDropdown = !menuDropdown"
        >
          <i class="dsi dsi-more-h"></i>
        </button>
        <a
          class="dosiin_cart-header-btn brand_cart-btn btn-circle brand-nav-btn cart-icon"
          href="/cart"
        >
          <svg
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7809 6.80932H13.3328V4.80893C13.3328 4.24298 13.2211 3.68259 13.0041 3.15976C12.787 2.63693 12.469 2.16192 12.068 1.76182C11.6671 1.36173 11.1912 1.0444 10.6674 0.827909C10.1436 0.611418 9.58231 0.5 9.01543 0.5C8.44856 0.5 7.88722 0.611418 7.36347 0.827909C6.83971 1.0444 6.36377 1.36173 5.96283 1.76182C5.5619 2.16192 5.24382 2.63693 5.0268 3.15976C4.80978 3.68259 4.69808 4.24298 4.69808 4.80893V9.24765C4.69808 9.38161 4.75141 9.50996 4.84614 9.60448C4.94084 9.69899 5.06915 9.75196 5.2028 9.75196C5.33644 9.75196 5.46475 9.69899 5.55945 9.60448C5.65418 9.50996 5.70752 9.38161 5.70752 9.24765V7.81795H10.791V6.80932H5.70752V4.80893C5.70752 3.93385 6.05587 3.09447 6.67615 2.4755C7.29645 1.8565 8.13791 1.50863 9.01543 1.50863C9.89296 1.50863 10.7344 1.8565 11.3547 2.4755C11.975 3.09447 12.3234 3.93385 12.3234 4.80893V9.21594C12.3234 9.34991 12.3767 9.47825 12.4714 9.57278C12.5661 9.66728 12.6944 9.72026 12.8281 9.72026C12.9617 9.72026 13.09 9.66728 13.1847 9.57278C13.2795 9.47825 13.3328 9.34991 13.3328 9.21594V7.81795H16.7714V19.4914H1.25944V7.81795H3.16576V6.80932H0.25V19.4187C0.25 19.7057 0.364258 19.9808 0.567427 20.1835C0.770574 20.3862 1.04596 20.5 1.33297 20.5H16.6979C16.9849 20.5 17.2603 20.3862 17.4634 20.1835C17.6666 19.9808 17.7809 19.7057 17.7809 19.4187V6.80932Z"
              fill="#484848"
            ></path>
          </svg>
          <span class="item-badge" style="top: 0.5px"> {{ cartAmount }}</span>
        </a>
      </div>
    </div>
    <!-- Menu Dropdown -->
    <Teleport to="#modals">
      <div
        class="more-menu-overlay modal"
        id="dosiin_more-menu"
        :class="menuDropdown ? 'open' : ''"
      >
        <div
          class="modal-more-menu"
          id="dosiin_more-menu-container"
          v-click-outside="() => (menuDropdown = false)"
        >
          <DropdownMobile />
        </div>
      </div>
    </Teleport>
  </header>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import vClickOutside from "click-outside-vue3";
import DropdownMobile from "@/components/menus/DropdownMobile";
export default {
  components: {
    DropdownMobile,
  },
  props: {
    brand: {
      type: Object,
      default: {},
    },
    extend_view: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const store = useStore();
    const menuDropdown = ref(false);
    const cartAmount = computed(() => store.getters["cart/cartTotalItems"]);

    return {
      cartAmount,
      menuDropdown,
    };
  },
};
</script>

<style scoped>
#modals .more-menu-overlay .modal-more-menu {
  right: 9.8% !important;
}
</style>
