<template>
  <PromotionShockDetailMB v-if="layout == 4" />
  <PromotionShockDetailPC v-else />
</template>

<script>
import PromotionShockDetailMB from "@/pages/mb/PromotionShockDetail";
import PromotionShockDetailPC from "@/pages/pc/PromotionShockDetail";
import { ref, watch, provide, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import PromotionServices from "@/services/promotionsshock";

export default {
  components: {
    PromotionShockDetailMB,
    PromotionShockDetailPC,
  },
  emits: ["totalBrands"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const pagination = ref({});
    const product = ref([]);

    const state = reactive({
      companyId: route.query.company_id,
      promotion_id: route.query.promotion_id,
      promotions_brand: [],
      promotion_shock: [],
      loading: true,
      page: 1,
      totalPage: 0,
      hidePagination: props.hidePagination,
    });

    async function onIntersectionPromotions() {
      getProduct();
    }

    async function getProduct() {
      state.loading = true;

      if (!state.companyId || !state.promotion_id) {
        router.push({ name: "products-shock" });
      } else {
        const response = await PromotionServices.fetchPromotionShockDetail(
          state.promotion_id,
          {
            promotion_id: state.promotion_id,
            company_id: state.companyId,
            page: state.page,
            items_per_page: 12,
          }
        );

        if (response.data) {
          product.value = response.data.promotion_shock;

          state.promotions_brand = response.data.promotion_companies;

          pagination.value = response.data.params;
          state.totalPage = Math.ceil(
            pagination.value.total_items / pagination.value.items_per_page
          );
          state.page = pagination.value.page;
          state.loading = false;

          emit("totalBrands", pagination.value.total_items);
        }
      }
    }

    watch(
      () => state.page,
      (page) => {
        window.scrollTo(0, 0);
        onIntersectionPromotions();
      }
    );

    provide("state", state);
    provide("product", product);
    provide("onIntersectionPromotions", onIntersectionPromotions);

    return {
      state,
      router,
    };
  },
};
</script>
