<template>
  <div>
    <BrandHeader :extend_view="true" />
    <Intersection @onIntersection="onIntersectionPromotions()">
      <LoadingComponent v-if="state.loading" />
      <template v-else>
        <section class="couponBrand section-header" v-if="product.company_data">
          <div class="brand-shop_banner-wrapper">
            <img
              v-lazy="{
                src: _bannerImage(product.company_data.banners_main),
                loading: _bannerDefault,
                error: _bannerDefault,
              }"
              height="375"
              class="dosiin_w-100"
            />
          </div>

          <div class="brand-name">
            <div class="brand-item_img-wrapper">
              <BrandLogo :brand="product.company_data" :width="32" :height="32" />
              <a
                :href="_brandUrl(product.company_data.seo_name)"
                :title="product.company_data.company"
                v-text="product.company_data.company"
              ></a>
            </div>
            <a
              class="store"
              :href="_brandUrl(product.company_data.seo_name)"
              :title="product.company_data.company"
            >
              <span class="text-pink-gradient dosiin_mr-1">Cửa hàng</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </a>
          </div>

          <div class="detail-coupon">
            <div class="promo-item dosiin-hidden">
              <div class="promo-content">
                <span class="promo-name">Rookie</span>
                <span class="promo-detail">Giảm 30k đơn 200k</span>
                <span class="promo-exp"
                  >Exp: <span class="text-pink-gradient">còn 27 ngày </span></span
                >
                <span class="promo-saved">x <span>28</span></span>
                <span class="promo-info-icon"></span>
              </div>
              <div class="copy-promo">
                <button
                  class="dossin_copy-promo-btn btn copy-promo-btn"
                  type="button"
                  name="Rookie"
                  value="promo1"
                >
                  Áp dụng
                </button>
              </div>
            </div>
            <div class="desc dosiin-hidden">
              <h4 class="title">Ưu đãi khuyến mãi</h4>
              <ul>
                <li>Đơn hàng giảm giá: 20,000₫</li>
              </ul>
            </div>
            <div class="desc dosiin-hidden">
              <h4 class="title">Điều kiện áp dụng</h4>
              <ul>
                <li>Không áp dụng chung với khuyến mãi khác</li>
                <li>Giảm tối đa 20,000 ₫</li>
                <li>Hạng thành viên: Thành viên Dosiin</li>
                <li>Mã giảm giá: JUNE20K</li>
              </ul>
            </div>
            <div class="desc">
              <h4 class="title">Điều kiện áp dụng</h4>
              <ul>
                <li>
                  Từ
                  <span>{{ _momentDateMonthYear(product.time_start) }} </span>
                  đến
                  <span>{{ _momentDateMonthYear(product.time_end) }}</span>
                </li>
              </ul>
            </div>
          </div>

          <section class="page-section" style="margin-top: 8px">
            <div class="header-section">
              <h2
                class="header-section-left-side section-title"
                v-text="product.name"
              ></h2>
            </div>
            <ul class="grid grid-50" id="dosiin_all-product-list">
              <template v-if="product.products.length != 0">
                <li class="grid-item" v-for="(product, i) in product.products" :key="i">
                  <ProductItem :product="product" />
                </li>
              </template>
              <p v-else>Không tìm thấy sản phẩm</p>
            </ul>
          </section>

          <section class="page-section" v-if="product.products.length != 0">
            <div class="pagination-bottom" id="pagination-bottom">
              <Pagination
                v-if="state.totalPage > 1 && !state.hidePagination"
                v-model="state.page"
                :pages="state.totalPage"
              />
            </div>
          </section>

          <section class="page-section brand_on-promo mostSearchBrand overflow">
            <div class="header-section">
              <h2 class="header-section-left-side section-title">Khuyến mãi sốc</h2>
            </div>
            <Swiper
              class="brand-list dosiin_modal-brand-swiper"
              :slidesPerView="5"
              :observer="true"
              :observeParents="true"
              :observeSlideChildren="true"
              :spaceBetween="9"
            >
              <Swiper-slide
                v-for="(brand, i) in state.promotions_brand"
                :key="i"
                class="brand-modal_item"
              >
                <router-link :to="_brandUrl(brand.seo_name)" :title="brand.name">
                  <div class="brand_logo">
                    <BrandLogo
                      :brand="brand"
                      :width="62"
                      :height="62"
                      imageClass="brand-logo_img"
                    />
                  </div>
                  <h5 class="brand-name truncate" v-text="brand.company"></h5>
                </router-link>
              </Swiper-slide>
            </Swiper>
          </section>
        </section>
      </template>
    </Intersection>
  </div>
</template>

<script>
import BrandHeader from "@/pages/mb/brand/Header";
import ProductItem from "@/components/product/ProductItem";
import { inject, onMounted, ref, watch } from "@vue/runtime-core";
import Pagination from "@/components/pagination/Pagination";

export default {
  components: {
    BrandHeader,
    ProductItem,
    Pagination,
  },
  setup() {
    const state = inject("state");
    const product = inject("product");
    const currentUser = inject("currentUser");
    const onIntersectionPromotions = inject("onIntersectionPromotions");

    return {
      product,
      state,
      modules: [Pagination],
      onIntersectionPromotions,
    };
  },
};
</script>
<style>
.brand_on-promo .brand-list .brand-logo_img {
  width: 62px !important;
  height: 62px !important;
}
.brand_on-promo .brand-list .brand-name {
  padding: 8px 4px;
  letter-spacing: 0.01em;
}
</style>
